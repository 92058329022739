export const countries = [
  {
    label: "England",
    id: "1",
  },
  {
    label: "Scotland",
    id: "2",
  },
  {
    label: "Wales",
    id: "3",
  },
  {
    label: "Northern Ireland",
    id: "4",
  },
];

export const countriesPGAForm = [
  {
    label: "England",
    value: 1,
  },
  {
    label: "Scotland",
    value: 2,
  },
  {
    label: "Wales",
    value: 3,
  },
  {
    label: "Northern Ireland",
    value: 4,
  },
];

export enum AlertTabKey {
  MANUAL_ALERTS = "manual-alerts",
  PGA_UPDATES = "pga-updates",
}
export const AlertTab = {
  [AlertTabKey.MANUAL_ALERTS]: {
    label: "Manual Alerts",
    typeKey: 1,
  },
  [AlertTabKey.PGA_UPDATES]: {
    label: "PGA Updates",
    typeKey: 4,
  },
};
