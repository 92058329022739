import { ToastOptions } from "react-toastify";

export enum UserRole {
  Admin = "admin",
  Buyer = "buyer",
  NoRole = "no_role",
  AgricultureManager = "agriculturemanager",
}

export const PAGE_DEFAULT = {
  pageSize: 10,
  firstPage: "1",
};

export const PAGE_SIZE_100 = "100";

export const DATE_TIME_FORMAT = "DD/MM/YYYY";

export const DATE_TIME_FORMAT_2 = "YYYY-MM-DD";

export const DATE_TIME_FORMAT_HOUR = "dddd, DD MMMM YYYY [at] HH:mm";

export const UK_TIMEZONE = "Europe/London";

export enum ReportType {
  Activity = "activity",
  Registration = "registration",
  Preference = "preference",
}

export enum NotificationTypes {
  CmsReport = "CmsReport",
  ToggleMaintenanceStatus = "ToggleMaintenanceStatus",
  AlertToLotEmailCms = "AlertToLotEmailCms",
  RefreshPbiReports = "RefreshPbiReports",
  FarmGroupCmsNotification = "FarmGroupCmsNotification",
  LoginSuccessEvent = "LoginSuccessEvent",
  FormEditingNotification = "FormEditingNotification",
  FormReviewingNotification = "FormReviewingNotification"

}

export enum ModeType {
  Cattle = "C",
  Sheep = "S",
}

export const modeOptions = [
  { id: "C", label: "Cattle" },
  { id: "S", label: "Sheep" },
];

export const commonToastOption: ToastOptions<{}> = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined as any,
  theme: "light",
};

export enum TypeSearch {
  Text = "Text",
  MultipleDropdown = "MultipleDropdown",
  DateRangeDropdown = "DateRangeDropdown",
  MultipleDropdownSearch = "MultipleDropdownSearch",
}

export const listBooleanDropDown = [
  { label: "Yes", id: "Y" },
  { label: "No", id: "N" },
];

export const listQuickRanges = [
  { label: "This week", id: "ThisWeek" },
  { label: "This month", id: "ThisMonth" },
  { label: "Last 30 days", id: "Last30Days" },
  { label: "Last 6 months", id: "Last6Months" },
  { label: "Last 5 years", id: "Last5Years" },
];

export const listQuickRangesForLoginLogs = [
  { label: "This week", id: "ThisWeek" },
  { label: "This month", id: "ThisMonth" },
  { label: "Last 30 days", id: "Last30Days" },
];

export const tabList = [
  {
    tab: "Quick Range",
    key: "QUICK_RANGE",
  },
  {
    tab: "Custom Range",
    key: "CUSTOM_RANGE",
  },
];

export enum AntdSortOrder {
  ASC = "ascend",
  DESC = "descend",
}

export enum ApiSortOrder {
  ASC = "A",
  DESC = "D",
}
